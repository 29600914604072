@charset "utf-8";

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.wrapper {
  display: block;
  min-width: 400px;
  max-height: calc(100vh - 50px);
  overflow: auto;
  padding: 0;
  background-color: #fff;
  border-radius: 8px;
}

.header {
  position: relative;
  border-bottom: 1px solid #dedede;
}
.header > h3 {
  font-size: 22px;
  margin: 10px 0;
  padding: 0 20px;
  font-weight: 300;
}

.container {
  padding: 20px;
}

.btnWrap {
  margin: 10px 0px;
  text-align: center;
}
