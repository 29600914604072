@charset "utf-8";

.Plogin h1 {
  color: #222;
  font-size: 2.625em;
  font-weight: 800;
  letter-spacing: -2px;
  line-height: 48px;
  text-align: center;
}
.Plogin .txt {
  margin-top: 43px;
  color: #666;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.Plogin .login_box {
  position: relative;
  width: 460px;
  margin: 40px auto 0;
  padding: 35px 30px;
  background-color: #fff;
  border-radius: 10px;
}
.Plogin .login_box .group {
  display: block;
}
.Plogin .login_box input[type='text'],
.Plogin .login_box input[type='password'] {
  width: 100%;
  height: 46px !important;
  padding: 0 12px;
  color: #666;
  font-size: 16px;
  background: #f5f5f5;
}
.Plogin .login_box .group input + input {
  margin-top: 15px;
}
.Plogin .login_box input[type='text']:-ms-input-placeholder,
.Plogin .login_box input[type='password']:-ms-input-placeholder {
  color: #aaa;
  opacity: 1;
}
.Plogin .login_box button {
  display: block;
  width: 100%;
  height: 59px;
  line-height: 59px;
  border-radius: 8px;
  text-align: center;
  background: #fc0001;
  margin-top: 20px;
}
.Plogin .login_box button span {
  display: block;
  position: relative;
  height: 100%;
  font-size: 1.4375em;
  color: #fff;
  font-weight: 500;
}
.Plogin .login_box .chk {
  margin-top: 12px;
  font-size: 0;
}
.Plogin .login_box .chk em {
  display: inline-block;
  height: 30px;
  margin-left: 25px;
  color: #666;
  font-size: 0.9375em;
}
.Plogin .list {
  width: 460px;
  margin: 30px auto 0;
}
.Plogin .list li {
  position: relative;
  padding-left: 10px;
  color: #666;
  font-size: 0.875em;
  line-height: 150%;
}
.Plogin .list li::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
  width: 4px;
  height: 4px;
  background: #666;
}
.Plogin .list li + li {
  margin-top: 5px;
}

@media (max-width: 800px) {
  .Plogin {
    padding: 0;
  }
  .Plogin h1 {
    font-size: 30px;
  }
  .Plogin .txt {
    margin-top: 10px;
    padding: 0 20px;
    font-size: 13px;
    line-height: 18px;
  }
  .Plogin .txt br {
    display: none;
  }
  .Plogin .login_box {
    width: 100%;
    margin: 20px auto 0;
    padding: 30px 25px;
  }
  .Plogin .login_box .group {
    width: 100%;
  }
  .Plogin .login_box button {
    left: 35px;
    top: 230px;
    height: 55px;
    line-height: 54px;
  }
  .Plogin .login_box .chk {
    margin-top: 5px;
  }
  .Plogin .list {
    margin-top: 20px;
    padding: 0 5px;
    width: 100%;
  }
  .Plogin .list li {
    padding-left: 9px;
    font-size: 14px;
    line-height: 19px;
  }
  .Plogin .list li + li {
    margin-top: 2px;
  }
  .Plogin .list li::before {
    top: 8px;
    width: 3px;
    height: 3px;
  }
}
