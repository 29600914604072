@charset "utf-8";

.error_msg {
  width: 400px;
  margin: 70px auto;
  text-align: center;
}
.error_msg i {
  display: block;
  margin: 0 auto 10px;
  width: 52px;
  height: 52px;
  background-color: #999;
  border-radius: 50%;
}
.error_msg i::before {
  display: contents;
  content: '!';
  line-height: 52px;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.error_msg h3 {
  font-size: 24px;
}
.error_msg p {
  font-size: 15px;
  color: #666;
}
.error_msg :global(.board_btn) {
  margin-top: 30px;
}
@media (max-width: 800px) {
  .error_msg {
    width: 80%;
  }
  .error_msg h3 {
    font-size: 20px;
  }
}
