@charset "utf-8";

/* 즐겨찾기 */
.favorite {
  display: block;
  float: right;
  margin-left: 2px;
  text-indent: -99999px;
  overflow: hidden;
  width: 30px;
  height: 100%;
  background: url('css/images/favorite.svg') no-repeat center/18px;
}
.favorite:hover {
  cursor: pointer;
}
.favorite.selected {
  background-image: url('css/images/favorite_on.svg');
}

.pageTitle {
  width: 100%;
  font-size: 1.75em;
  text-align: left;
  padding-top: 20px;
  margin-bottom: 10px;
  height: 60px;
}

@media (max-width: 800px) {
  .pageTitle {
    font-size: 32px;
    padding-top: 15px;
  }
}
