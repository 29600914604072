@charset "utf-8";

.header {
  position: relative;
  background-color: #fff;
}
.header::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #dedede;
}
.header .headerWeb {
  display: block;
  position: relative;
  height: 82px;
  margin: 0 auto;
  padding: 0 60px;
}
.header .headerLogo {
  display: block;
  float: left;
  margin-right: 50px;
  margin-top: 25px;
}
.header .headerUserInfo {
  position: absolute;
  right: 90px;
  top: 24px;
  line-height: 30px;
  font-size: 15px;
}
.header .headerUserInfo .headerPerson {
  display: inline-block;
  font-size: 15px;
}
.header .headerUserInfo .headerPerson::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  background: url(css/images/ico_person.png) no-repeat;
  background-size: contain;
  vertical-align: -4px;
}
.header .headerUserInfo :global(.btn) {
  position: relative;
  padding: 0 30px 0 8px;
  color: #333;
  font-size: 15px;
  line-height: 30px;
  background-color: transparent;
  margin-top: 0;
}
.header .headerUserInfo :global(.btn)::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 0;
  width: 22px;
  height: 14px;
  background-color: #fc0001;
  border-radius: 15px;
}
.header .headerUserInfo :global(.btn)::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 9px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 15px;
}
.header .headerUserInfo :global(.btn).headerLogin::before {
  background-color: #ccc;
}
.header .headerUserInfo :global(.btn).headerLogin::after {
  right: 3px;
}
.header .headerGnb {
  display: block;
  float: left;
}
.header .headerGnb ul {
  font-size: 0;
  text-align: center;
}
.header .headerGnb ul::after {
  content: '';
  display: block;
  clear: both;
}
.header .headerGnb ul li {
  float: left;
}
.header .headerGnb ul li + li {
  margin-left: 45px;
}
.header .headerGnb ul li div {
  color: #333;
  font-size: 17px;
  line-height: 81px;
  font-weight: 700;
  letter-spacing: -0.25px;
  cursor: pointer;
}
.header .headerGnb ul li div:hover {
  color: #fc0001;
}
.header .headerGnb ul li div.selected {
  color: #fd0000;
}

/* Mobile */
.header .headerMobile {
  display: none;
}

.fullscreenIcon {
  position: absolute;
  right: 60px;
  top: 24px;
  line-height: 30px;
  font-size: 15px;
}
.fullscreenIcon button {
  position: relative;
  width: 28px;
  height: 28px;
  border: 0 !important;
  text-indent: -99999px;
  outline: 0;
}
.fullScreen {
  background: url('css/images/full.png') no-repeat center;
}
.originScreen {
  background: url('css/images/origin.png') no-repeat center;
}

.fullscreenIcon button:hover .tooltip {
  display: inline-block;
  position: absolute;
  top: 32px;
  right: 5px;
  width: max-content;
  height: 23px;
  line-height: 21px;
  padding: 0 7px;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #333;
  border-radius: 4px;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
  text-indent: 0;
  z-index: 100;
}

@media (max-width: 800px) {
  .header .headerWeb {
    display: none;
  }
  .header .headerMobile {
    display: block;
    width: 100%;
    height: 64px;
    padding: 0 20px;
  }

  .headerMobileLeft {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 100%;
    line-height: 140%;
    padding-top: 14px;
    text-align: center;
  }
  .headerMobileLearn {
    background-color: #fff;
    font-size: 10px;
    color: #999;
  }
  .headerMobileLearn::before {
    display: block;
    content: '';
    width: 100%;
    height: 25px;
    background: url(css/images/m_learn.png) no-repeat center;
  }

  .header .headerMobileLogo {
    float: none;
    margin-right: 0;
    padding-top: 9px;
    display: block;
    width: 100%;
    text-align: center;
  }
  .header .headerMobileLogo a,
  .header .headerMobileLogo img {
    display: inline-block;
    margin-top: 5px;
  }

  .headerMobileRight {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 100%;
    line-height: 140%;
    padding-top: 14px;
    text-align: center;
  }
  .headerMobileUser {
    background-color: #fff;
    font-size: 10px;
    color: #999;
  }
  .headerMobileUser::before {
    display: block;
    content: '';
    width: 100%;
    height: 25px;
    background: url(css/images/m_login.png) no-repeat center;
  }
}
