@charset "utf-8";

/* jqWidgets */
.jqx-grid {
  width: 100%;
  height: 100%;
}
.jqx-widget-header {
  background-color: #f0f1f2;
  font-size: 14px;
  color: #333;
  font-weight: 600;
  height: 23px;
}
.jqx-widget-header > div,
.jqx-widget-header > div > div,
.jqx-grid-column-header > div > div {
  line-height: 23px;
}
.jqx-grid-column-header > div > div span {
  font-size: 15px;
}
.jqx-widget-header.jqx-tabs-headerWrapper {
  background-color: #e5e5e5;
  height: 32px !important;
}
.jqx-tabs-content {
  height: 500px !important;
  padding: 5px 10px;
}
.jqx-widget .jqx-grid-column-header {
  border-right: 1px dashed #d5d5d5;
  font-size: 14px;
  font-weight: 500;
}
.jqx-widget-content {
  border-radius: 8px;
  border-color: #e5e5e5 !important;
}
.jqx-tree-item {
  font-size: 14px;
}
.jqx-widget-header {
  background-color: #fff3d9;
}
.jqx-overflow-hidden {
  border-radius: 4px;
}
.jqx-tabs {
  border: 0;
  border-radius: 0;
}
.jqx-tabs-titleContentWrapper {
  margin-top: 0 !important;
}
.jqx-tabs-title-selected-top {
  background: #fff5f5;
  border-bottom: 1px solid #fff5f5;
}
.jqx-tabs-title-selected-top .jqx-disableselect {
  color: #de071a;
}
.jqx-grid-statusbar {
  height: 25px !important;
}
.jqx-grid .jqx-grid-cell-sort {
  background-color: #eaebef;
}
.jqx-grid .jqx-grid-cell-left-align {
  padding: 0 5px;
}
.jqx-grid .jqx-fill-state-pressed {
  background-color: #fce6e8;
  /*box-shadow: 0 2px 5px rgb(0 0 0 / 20%);*/
}
.jqx-grid .jqx-fill-state-pressed:first-child::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: var(--search-button-bg);
}
.jqx-grid .jqx-grid-cell-pinned {
  background-color: var(--result-cell-pinned-bg);
  color: var(--result-cell-pinned-font-color);
  /*box-shadow: 2px 0 5px rgb(0 0 0 / 20%);*/
}
.jqx-grid .jqx-fill-state-hover {
  background-color: #f1f2f5 !important;
}

/* Board */
.board_btn {
  position: relative;
  min-height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}
.board_btn button,
.board_btn .btn {
  margin-top: 0;
  height: 35px;
  line-height: 31px;
  padding: 0 20px;
  background-color: #40404e;
  border-radius: 3px;
  color: #fff;
}
.board_btn.center {
  text-align: center !important;
}

.req:after {
  display: inline-block;
  content: '';
  width: 7px;
  height: 7px;
  margin-left: 2px;
  font-size: 0;
  background: url(css/images/ico_req.png) no-repeat;
  vertical-align: top;
  margin-top: 9px;
}

/* Form */
input:not([type='checkbox']),
select {
  width: 100%;
  height: 32px;
}

input:not([type='checkbox']),
select,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 8px;
}

input:not([type='checkbox']):disabled,
select:disabled,
textarea:disabled {
  background-color: #f5f5f5;
}

/* for IE10 */
input::placeholder {
  color: #aaa;
  opacity: 1; /* 파이어폭스에서 뿌옇게 나오는 현상을 방지하기 위한 css */
}
input::-webkit-input-placeholder {
  color: #aaa;
}
/* IE */
input:-ms-input-placeholder {
  color: #aaa;
}
input[type='text']::-ms-clear {
  display: none;
}
/* Firefox */
input:-mos-input-placeholder {
  color: #aaa;
}

select {
  position: relative;
  font-family: 'NotoSans', sans-serif;
  font-weight: 400;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}
select::-ms-expand {
  display: none;
}

input[type='radio'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 0;
}
input[type='radio']::before {
  display: block;
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-right: 4px;
  border-radius: 14px;
}
input[type='radio']:checked::before {
  display: block;
  content: '';
  background-color: #40404e;
  border-color: #40404e;
}
input[type='radio']:checked::after {
  display: block;
  content: '';
  position: absolute;
  top: 9px;
  left: 5px;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 14px;
}

/* input[type='checkbox'] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 0;
}
input[type='checkbox']::before {
  display: block;
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-right: 4px;
  border-radius: 4px;
}
input[type='checkbox']:checked::before {
  display: block;
  content: '';
  background-color: #40404e;
  border-color: #40404e;
}
input[type='checkbox']:checked::after {
  display: block;
  content: '';
  position: absolute;
  top: 6px;
  left: 5px;
  width: 4px;
  height: 7px;
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
} */

/* 리액트 기타 package 관련 */
.MuiInput-underline::before,
.MuiInput-underline::after {
  display: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: inline-block;
  vertical-align: top;
  width: auto;
}
.react-datepicker__tab-loop {
  display: inline-block;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

/* Button */
.btn_wrap {
  margin-top: 20px;
  text-align: center;
}
.btn {
  display: inline-block;
  vertical-align: top;
  border: 0;
  border-radius: 3px;
  margin: 20px 3px 0;
}
.btn > span {
  position: relative;
}

.btn_wrap .btn {
  margin: 0 3px;
}
.btn_wrap.left {
  text-align: left;
}
.btn_wrap.right {
  text-align: right;
}

.btn_wrap.left button:first-child {
  margin-left: 0;
}
.btn_wrap.right button:last-child {
  margin-right: 0;
}

.btnL {
  height: 59px;
  background-color: #fc0001;
  font-size: 1.4375em;
  color: #fff;
  padding: 0 20px;
}
.btnL.icon::before {
  display: inline-block;
  content: '';
  width: 21px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
}
.btnL.icon.cam::before {
  background-image: url(css/images/cam.png);
}
.btnL.icon.stop::before {
  background-image: url(css/images/stop.png);
}
.btnL.icon.edit::before {
  background-image: url(css/images/edit.png);
}
.btnL.icon.pic::before {
  background-image: url(css/images/pic.png);
  width: 25px;
  height: 23px;
}
.btnL.icon.back {
  background-color: #40404e;
}
.btnL.icon.back::before {
  background-image: url(css/images/undo.png);
}
.btnM {
  height: 43px;
  background-color: #40404e;
  font-size: 1.125em;
  color: #fff;
  padding: 0 14px;
}
.btnM.icon::before {
  display: inline-block;
  content: '';
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 9px;
}
.btn.icon.down::before {
  background-image: url(css/images/download.png);
}
.btn.icon.del::before {
  background-image: url(css/images/del.png);
}

@media (max-width: 800px) {
  .btn_wrap.left,
  .btn_wrap.right {
    text-align: center;
  }
  .btnM {
    margin-top: 15px;
  }
  .btnL {
    height: 50px;
  }
}

/* Wijmo Grid */
.container-fluid{ height:100% }

.wj-colheaders .wj-row > div {
  text-align: center;
}
