@charset "utf-8";

.tab {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin: -15px 0 15px;
}

.tab_menu {
  width: calc(100% - 98px);
  height: 60px;
  overflow: hidden;
}
.tab_menu ul {
  width: 200%;
}
.tab_menu ul::after {
  display: block;
  content: '';
  clear: both;
}
.tab_menu li {
  float: left;
  padding: 0 2px;
  border-bottom: 3px solid transparent;
  margin-right: 10px;
}
.tab_menu li a {
  position: relative;
  display: inline-block;
  padding: 19px 0 16px;
  font-size: 15px;
  color: #666;
}
.tab_menu li a.close {
  width: 12px;
  height: 12px;
  padding: 0;
  text-indent: -99999px;
  overflow: hidden;
  margin-left: 5px;
  opacity: 0.7;
}
.tab_menu li a.close::before,
.tab li a.close::after {
  display: block;
  content: '';
  width: 12px;
  height: 1px;
  background-color: #777;
  position: absolute;
  top: 6px;
  right: 0;
  transform: rotate(45deg);
}
.tab_menu li a.close::after {
  transform: rotate(135deg);
}
.tab_menu li.selected {
  border-color: #fd0000;
}
.tab_menu li.selected a {
  color: #fd0000;
  font-weight: 500;
}
.tab_menu li.selected a.close::before,
.tab li.selected a.close::after {
  background-color: #fd0000;
}

.tab_control {
  position: absolute;
  top: calc(50% - 16px);
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.tab_control::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: -31px;
  width: 30px;
  height: 100%;
  background: linear-gradient(90deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
}
.tab_control::after {
  display: block;
  content: '';
  clear: both;
}
.tab_control a {
  position: relative;
  display: block;
  float: left;
  width: 32px;
  height: 30px;
  text-indent: -99999px;
  overflow: hidden;
  border-right: 1px solid #ddd;
}
.tab_control a:last-child {
  border-right: 0;
}
.tab_control .prev::before,
.tab_control .next::before {
  display: block;
  content: '';
  width: 6px;
  height: 6px;
  border: 1px solid #777;
  border-width: 1.5px 1.5px 0 0;
  position: absolute;
  top: calc(50% - 4px);
  left: calc(50% - 2px);
  transform: rotate(225deg);
}
.tab_control .next::before {
  left: calc(50% - 6px);
  transform: rotate(45deg);
}
.tab_control .all_close::before,
.tab_control .all_close::after {
  display: block;
  content: '';
  width: 12px;
  height: 1px;
  background-color: #777;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 6px);
  transform: rotate(45deg);
}
.tab_control .all_close::after {
  transform: rotate(135deg);
}

@media (max-width: 800px) {
  .tab {
    display: none;
  }
}
