@charset "utf-8";

@media (max-width: 800px) {
  body {
    min-width: auto;
  }
  * {
    font-size: 15px;
  }

  .col30,
  .col40,
  .col45,
  .col55,
  .col60,
  .col70 {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .row > div:nth-child(2) {
    margin-top: 30px;
  }

  .mt-20 {
    margin-top: 10px !important;
  }
  .mt-40 {
    margin-top: 20px !important;
  }
  .mt-60 {
    margin-top: 30px !important;
  }

  h4 {
    font-size: 1.7em;
  }
  .mobile-hidden {
    display: none;
  }
}
