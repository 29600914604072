@charset "utf-8";

/* Web Font */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');
}

/* Reset style */
* {
  word-break: keep-all;
  word-wrap: break-word;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
em,
font,
img,
ins,
q,
s,
small,
strike,
strong,
sub,
sup,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
thead,
tbody,
tfoot,
tr,
th,
td,
input {
  margin: 0;
  padding: 0;
}

blockquote,
button,
fieldset,
iframe {
  border: 0;
}
cite,
em,
u,
address,
i {
  font-style: normal;
}
img {
  border: 0;
  vertical-align: top;
}
hr,
legend {
  position: absolute;
  left: -9999px;
  top: -9999px;
}
caption {
  text-indent: -9999px;
  font-size: 0;
}
li {
  list-style: none;
}
table {
  table-layout: fixed;
  border-collapse: collapse;
}
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
}
button,
textarea {
  font-family: 'Noto Sans KR', sans-serif;
}
button {
  cursor: pointer;
}
a {
  cursor: pointer;
}
video {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

h4 {
  font-size: 2.25em;
  font-weight: 500;
  line-height: 135%;
}
h4 span {
  font-size: 1em;
  color: #fd0000;
}
h5 {
  font-size: 2em;
  font-weight: 500;
  line-height: 135%;
}
h4 + p {
  color: #666;
}

hr {
  position: relative;
  top: auto;
  left: auto;
  margin: 20px 0;
  height: 1px;
  background: #ccc;
  border: 0;
}
