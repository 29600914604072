@charset "utf-8";

.modal_wrap .send {
  display: inline-block;
  height: 35px;
  line-height: 33px;
  padding: 0 15px 0 10px;
  background-color: #fd0000;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
}
.modal_wrap .send::before {
  display: inline-block;
  content: '';
  width: 18px;
  height: 19px;
  vertical-align: top;
  margin-top: 6px;
  background: url(css/images/send.png) no-repeat center;
  margin-right: 5px;
}

.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal_wrap .modal {
  display: block;
  width: 1320px;
  padding: 50px 80px 60px;
  background-color: #fff;
  border-radius: 8px;
}
.modal_wrap .modal h3 {
  font-size: 2.625em;
  color: #009933;
  text-align: center;
  margin-bottom: 20px;
}
/* 모달 - 문자전송 */
.modal_wrap.sms_send .modal {
  position: relative;
  width: 300px;
  height: 385px;
  padding: 35px 20px 20px;
  border: 10px solid #eee;
  border-radius: 35px;
}
.modal_wrap.sms_send .modal::before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 60px;
  width: calc(100% - 120px);
  height: 22px;
  background-color: #eee;
  border-radius: 0 0 20px 20px;
}
.modal_wrap.sms_send .modal h3 {
  font-size: 22px;
  color: #333;
  margin: 0 0 12px;
}
.modal_wrap.sms_send .close {
  position: absolute;
  top:/*35px*/ 7px;
  right: 0;
  width: 35px;
  height: 35px;
  text-indent: -99999px;
  overflow: hidden;
}
.modal_wrap.sms_send .close::before,
.modal_wrap .close::after {
  display: block;
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  width: 18px;
  height: 2px;
  background-color: #666;
  transform: rotate(45deg);
}
.modal_wrap.sms_send .close::after {
  transform: rotate(135deg);
}
.modal_wrap.sms_send .modal_container {
  height: calc(100% - 85px);
  padding: 10px 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #444;
}

.modal_wrap_common {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1400px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal_wrap_common .modal {
  display: block;
  min-width: 400px;
  width: 1112px;
  max-height: calc(100vh - 50px);
  overflow: auto;
  padding: 0;
  background-color: #fff;
  border-radius: 8px;
}
.modal_wrap_common .modal_header {
  position: relative;
  border-bottom: 1px solid #dedede;  
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.modal_wrap_common h3 {
  font-size: 22px;
  padding: 0 20px;
}
.modal_wrap_common .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  text-indent: -99999px;
  overflow: hidden;
}
.modal_wrap_common .close::before,
.modal_wrap_common .close::after {
  display: block;
  content: '';
  position: absolute;
  top: 30px;
  left: 10px;
  width: 18px;
  height: 2px;
  background-color: #666;
  transform: rotate(45deg);
}
.modal_wrap_common .close::after {
  transform: rotate(135deg);
}
.modal_wrap_common .modal_container {
  padding: 20px;
}

.modal_wrap_common .modal_btn_wrap {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dedede;
}

@media (max-width: 800px) {
  .modal_wrap {
    min-width: unset;
  }
  .modal_wrap .modal {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    border-radius: 0;
  }
  .modal_wrap .modal h3 {
    font-size: 2em;
  }
}
