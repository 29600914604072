@charset "utf-8";

body {
  min-width: 1400px;
  background-color: #f5f5f5;
}

.hidden {
  display: none;
}

/* 여백 */

.mt-20 {
  margin-top: 20px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-60 {
  margin-top: 60px !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.row::after {
  display: block;
  content: '';
  clear: both;
}
.col20 {
  width: 20%;
}
.col30 {
  width: 30%;
  padding-right: 80px;
}
.col40 {
  width: 40%;
}
.col45 {
  width: 45%;
}
.col55 {
  width: 55%;
  padding-right: 111px;
}
.col60 {
  width: 60%;
}
.col70 {
  width: 70%;
}
.col80 {
  width: 80%;
}

.board_list {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  height: 400px;
  margin: 0px 0px 10px 0px;
  /* height: 400px; */
  flex: 1 0 400px;
}

.board_view {
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
}
.board_view:first-child {
  margin-top: 0;
}
.row .board_view:nth-child(2) {
  margin-top: 0;
  border-radius: 0 8px 8px 0;
}
.board_view * {
  font-size: 15px;
}
.board_view th {
  width: 160px;
  padding: 10px 20px;
  color: #000;
  font-weight: 500;
  word-break: break-all;
  border-right: 1px dashed #e5e5e5;
  border-bottom: 1px solid #eee;
  text-align: left;
}
.board_view td {
  padding: 10px 15px;
  color: #666;
  text-align: left;
  border-bottom: 1px solid #eee;
}
.board_view tr:last-child th,
.board_view tr:last-child td {
  border-bottom: 0;
}

.page_content {
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
}

/* 조회조건 */
.search_section {
  position: relative;
  padding: 18px 140px 18px 20px;
  margin-bottom: 10px;
  background-color: #e5e5e5;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 0;
  text-align: center;
}
.search_section * {
  font-size: 15px;
}
.search_section th,
.search_section td {
  vertical-align: top;
  padding-bottom: 5px;
}
.search_section th {
  min-width: fit-content;
}
.search_section td {
  text-align: left;
}
.search_section label {
  display: block;
  text-align: right;
  color: #222;
  font-weight: 600;
  vertical-align: top;
  padding: 5px 10px 5px 15px;
}
.search_section .search {
  display: inline-block;
  height: 35px;
  line-height: 33px;
  padding: 0 15px 0 10px;
  position: absolute;
  top: -4px;
  right: 35px;
  background-color: #fd0000;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
}
.search_section .search::before {
  display: inline-block;
  content: '';
  width: 18px;
  height: 19px;
  vertical-align: top;
  margin-top: 6px;
  background: url(css/images/search.png) no-repeat center;
  margin-right: 5px;
}

.mobile-hidden {
  display: block;
}

.search_section table {
  width: 100%;
}
