@charset "utf-8";

.nav {
  width: 200px;
  overflow: hidden;
  max-height: 670px;
}

.sidemenuLine {
  position: relative;
  width: 60px;
  height: 100%;
  border-left: 1px solid #ddd;
}

.inner {
  height: calc(100% - 120px);
  overflow: auto;
}
.menuSearch + .inner {
  height: calc(100% - 150px);
}
.menuSearch {
  position: relative;
  width: calc(100% - 15px);
}
.menuSearch input {
  padding-right: 35px;
}
.menuSearch .btnMenuSearch {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  width: 32px;
  height: 32px;
  background: url('css/images/search.svg') no-repeat center;
  text-indent: -99999px;
}

.inner li {
  cursor: pointer;
}

.nav h2 {
  padding: 0;
  color: #222;
  font-size: 22px;
}
.nav ul {
  padding-top: 12px;
}
.nav ul:first-child {
  padding-top: 5px;
}
.inner > ul > li {
  display: block;
  position: relative;
  color: #666;
  font-size: 15.5px;
  padding: 8px 35px 8px 0;
  border-bottom: 1px dashed #ddd;
}
.inner > ul > li::before,
.inner > ul > li::after {
  content: '';
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;
  transform: translateY(-50%);
  width: 12px;
  height: 1px;
  background-color: #888;
}

.inner > ul > li::after {
  display: none;
  top: 20px;
  right: 26px;
  width: 1px;
  height: 12px;
}
.inner > ul > li.collapsed::before,
.inner > ul > li.collapsed::after {
  display: block;
}
.inner > ul > li.expanded::before {
  display: block;
}
.inner > ul > li.selected {
  color: #fd0000;
  font-weight: 500;
}
.inner > ul > li.selected::before,
.inner > ul > li.selected::after {
  background-color: #fd0000;
}
.inner li ul {
  margin-right: -35px;
}
.inner li ul li,
.inner li ul li:hover,
.inner li ul li.selected {
  position: relative;
  padding: 2px 15px 2px 17px;
  font-size: 14px;
  color: #666;
  border-bottom: 0;
}
.inner li ul li.selected {
  color: #fd0000;
}
.inner li ul li::before,
.inner li ul li:hover::before,
.inner li ul li.select::before {
  display: block;
  content: '';
  position: absolute;
  top: 0 !important;
  left: 5px !important;
  width: 1px !important;
  height: 100% !important;
  border: 0 !important;
  border-left: 1px solid #ccc !important;
}
.inner li ul li::after,
.inner li ul li:hover::after,
.inner li ul li.select::after {
  display: block;
  content: '';
  position: absolute;
  top: 11px !important;
  left: 5px !important;
  width: 7px !important;
  height: 1px !important;
  background: none !important;
  border: 0 !important;
  border-bottom: 1px solid #ccc !important;
}
.inner li ul li:last-child:before {
  top: 0 !important;
  height: 12px !important;
}

.nav ul li a.cur {
  color: #fd0000;
  font-weight: 700;
}
.nav ul li a.cur::after {
  content: '';
  display: block;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 9px;
  height: 15px;
  background: url(css/images/ico_arrow_r_red_9x15.png) no-repeat;
}

/* 메뉴 자동감춤 */
.sidemenuHidden {
  margin-top: 20px;
  width: 100%;
  text-align: right;
  padding-right: 15px;
}
.sidemenuHidden span {
  position: relative;
  font-size: 14px;
  color: #888;
  padding-right: 40px;
  cursor: pointer;
}

.sidemenuHidden em.off {
  display: flex;
  position: absolute;
  bottom: 1px;
  right: 0;
  height: 14px;
  background-color: #ccc;
  border-radius: 30px;
  padding: 2px 3px;
}
.sidemenuHidden em.off::before {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 30px;
  margin-right: 1px;
}
.sidemenuHidden em.off::after {
  display: inline-block;
  content: 'off';
  font-size: 10px;
  color: #fff;
  line-height: 10px;
  margin-right: 2px;
}
.sidemenuHidden em.on {
  background-color: #fc0001;
}
.sidemenuHidden em.on::before {
  display: inline-block;
  content: 'on';
  width: auto;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  font-size: 10px;
  color: #fff;
  line-height: 10px;
  margin-left: 2px;
}
.sidemenuHidden em.on::after {
  display: inline-block;
  content: '';
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 30px;
  margin-left: 1px;
  margin-right: 0;
}

/* 왼쪽 메뉴 tab */
.sidemenuTab {
  margin: -10px 0 10px;
  border-bottom: 1px solid #ddd;
}
.sidemenuTab ul {
  padding-top: 0 !important;
}
.sidemenuTab ul::after {
  display: block;
  content: '';
  clear: both;
}
.sidemenuTab li {
  float: left;
  width: 33.333%;
  border-bottom: 3px solid transparent;
}
.sidemenuTab li.selected {
  border-color: #fd0000;
}
.sidemenuTab li a {
  display: block;
  width: 100%;
  height: 57px;
  padding: 0 !important;
  text-indent: -99999px;
  overflow: hidden;
  border-bottom: 0 !important;
  background: url('css/images/all_menu.svg') no-repeat center;
}
.sidemenuTab li a.like {
  margin-left: 0;
  background-image: url('css/images/like.svg');
}
.sidemenuTab li a.menu {
  background-image: url('css/images/all_menu.svg');
}
.sidemenuTab li a.search {
  background-image: url('css/images/search.svg');
}
.sidemenuTab li a.like:hover,
.sidemenuTab li.selected a.like {
  background-image: url('css/images/like_on.svg');
}
.sidemenuTab li a.menu:hover,
.sidemenuTab li.selected a.menu {
  background-image: url('css/images/all_menu_on.svg');
}
.sidemenuTab li a.search:hover,
.sidemenuTab li.selected a.search {
  background-image: url('css/images/search_on.svg');
}
.sidemenuTab li a:hover::after {
  display: none !important;
}

/* 왼쪽메뉴 열기/닫기 */
.sidemenuControl {
  display: block;
  position: absolute;
  top: 300px;
  left: -1px;
  width: 20px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left: 0;
  border-radius: 0 5px 5px 0;
}
.sidemenuControl::before {
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  border: 1px solid #777;
  border-width: 1.5px 1.5px 0 0;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 7px);
  transform: rotate(45deg);
}

/* 왼쪽메뉴 열기/닫기 */
.sidemenuControl {
  display: block;
  position: absolute;
  top: 300px;
  left: -1px;
  width: 20px;
  height: 36px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left: 0;
  border-radius: 0 5px 5px 0;
}
.sidemenuControl::before {
  display: block;
  content: '';
  width: 7px;
  height: 7px;
  border: 1px solid #777;
  border-width: 1.5px 1.5px 0 0;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 7px);
  transform: rotate(45deg);
}
