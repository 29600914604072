@charset "utf-8";

.footer {
  position: relative;
  padding: 20px 60px;
  background-color: #333;
}
.footer .footerInner {
  position: relative;
  margin: 0 auto;
}
.footer .footerInner::after {
  content: '';
  display: block;
  clear: both;
}

.footer .footerLogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 50px;
  background: url(css/images/f_logo.png) no-repeat right top;
  text-indent: -99999px;
  overflow: hidden;
}
.footer h3 {
  font-size: 18px;
  color: #de0000;
  font-weight: 700;
}
.footer p {
  font-size: 14px;
  color: #999;
  padding: 2px 0;
}

@media (max-width: 800px) {
  .footer {
    height: auto;
    padding: 20px 0;
  }
  .footerLogo,
  .footer h3,
  .footer p {
    display: none;
  }
  .footer .footerCopyright {
    display: block;
    text-align: center;
  }
}
