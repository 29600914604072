@charset "utf-8";

/* 메인 */
.P_MAIN {
  position: relative;
  background: #f5f5f5;
}

.wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.P_MAIN h3 {
  font-size: 3em;
  color: #333;
  font-weight: 500;
  line-height: 120%;
  padding: 60px 0 50px;
  text-align: center;
}
.P_MAIN h3 span {
  font-size: 1em;
  color: #fd0000;
}

.P_MAIN .slider {
  width: 100%;
  height: 630px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 95px;
}
.P_MAIN .slider .main_slide1,
.P_MAIN .slider .main_slide2 {
  width: 100%;
  height: 630px;
  padding: 40px 80px 50px;
}
.P_MAIN .slider .main_slide1 {
  background: url(css/images/main_img1.jpg) no-repeat center / cover;
}
.P_MAIN .slider .main_slide2 {
  background: url(css/images/main_img2.jpg) no-repeat center / cover;
}

.P_MAIN .tube_inner h4 span {
  color: inherit;
}
.P_MAIN .tube_inner .tube_control {
  right: 83px;
}
.P_MAIN .slider > div:nth-child(2) {
  top: auto;
  bottom: 50px;
  right: 60px;
  width: 38px;
  height: 43px;
}
.P_MAIN .slider > div:nth-child(2) button {
  border-radius: 0 8px 8px 0;
  margin: 0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.P_MAIN .slider > div:nth-child(2) button:hover {
  background-color: #22222c;
  opacity: 1 !important;
}
.P_MAIN .slider > div:nth-child(3) {
  top: auto;
  bottom: 50px;
  left: auto;
  right: 98px;
  width: 39px;
  height: 43px;
}
.P_MAIN .slider > div:nth-child(3) button {
  border-radius: 8px 0 0 8px;
  margin: 0;
  border-right: 1px solid #22222c;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
.P_MAIN .slider > div:nth-child(3) button:hover {
  background-color: #22222c;
  opacity: 1 !important;
}

.tube_inner h4 {
  font-size: 2.25em;
  color: #fff;
  font-weight: 500;
  line-height: 120%;
  padding-top: 50px;
}

.tube_inner .cam_able {
  position: relative;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  height: 43px;
  padding: 0 17px;
  background-color: #009933;
  border-radius: 8px;
  font-size: 1.125em;
  color: #fff;
  font-weight: 500;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.tube_inner .cam_able::before {
  display: inline-block;
  content: '';
  width: 21px;
  height: 13px;
  background: url(css/images/cam.png) no-repeat;
  margin-right: 10px;
}
.tube_inner .tube_control {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 43px;
  background-color: #40404e;
  border-radius: 8px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}
.tube_inner .tube_control::after {
  display: block;
  content: '';
  clear: both;
}
.tube_inner .tube_control li {
  float: left;
}
.tube_inner .tube_control li button {
  display: inline-block;
  font-size: 0;
}

.tube_inner .rec_block {
  position: absolute;
  bottom: -10px;
  left: calc(50% - 31.5px);
  width: 63px;
  height: 63px;
  background-color: #fd0000;
  border-radius: 50%;
  font-size: 0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  outline: 0px solid rgba(255, 255, 255, 0);
  transition: all 0.3s;
}
.tube_inner .rec_block::before {
  display: block;
  content: '';
  position: absolute;
  top: 17px;
  left: 17px;
  width: 14px;
  height: 14px;
  border: 7px solid #fff;
  border-radius: 50%;
  margin: 0 auto;
}

.tube_inner .btn_group {
  position: relative;
  height: 36px;
  padding: 4px;
  background-color: #22222c;
  border-radius: 6px;
  vertical-align: top;
}
.tube_inner .btn_group .cam {
  width: 34px;
  height: 28px;
  background: transparent url(css/images/cam_off.png) no-repeat center;
  border-radius: 4px;
  vertical-align: top;
  opacity: 0.4;
}
.tube_inner .btn_group .cam.on {
  background-color: #40404e;
  background-image: url(css/images/cam_on.png);
  opacity: 1;
}
.tube_inner .btn_group .sound {
  position: relative;
  width: 34px;
  height: 28px;
  background: transparent url(css/images/sound_off.png) no-repeat center;
  border-radius: 4px;
  vertical-align: top;
  opacity: 0.4;
}
.tube_inner .btn_group .sound.on {
  background-color: #40404e;
  background-image: url(css/images/sound_on.png);
  opacity: 1;
}
.tube_inner .btn_group > button {
  margin-left: 3px;
}
.tube_inner .btn_group > button:first-child {
  margin-left: 0;
}
.tube_inner .screen {
  position: relative;
  width: 42px;
  height: 43px;
  background: url(css/images/screen.png) no-repeat center;
}
.tube_inner .play_block {
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 63px;
  height: 63px;
  background: #fd0000 url(css/images/play.png) no-repeat 55% 50%;
  border-radius: 50%;
  font-size: 0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  outline: 0px solid rgba(255, 255, 255, 0);
  transition: all 0.3s;
}
.tube_inner .state_bar {
  position: absolute;
  bottom: 18px;
  left: 90px;
  width: calc(100% - 180px);
  height: 6px;
  background-color: #fff;
}

.tube_inner {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
  .wrap {
    width: 100%;
    padding: 0 20px 30px;
  }

  .P_MAIN::before {
    content: none;
  }

  .P_MAIN h3 {
    font-size: 2.8em;
    padding: 30px 0 0;
  }

  .P_MAIN .slider {
    height: 360px;
    margin-bottom: 0;
    margin-top: 30px;
  }
  .P_MAIN .slider .main_slide1,
  .P_MAIN .slider .main_slide2 {
    height: 360px;
    padding: 20px;
  }
  .P_MAIN .tube_inner h4 {
    font-size: 1.9em;
    padding-top: 0;
    position: absolute;
    bottom: 50px;
    left: 0;
  }
  .P_MAIN .tube_inner .tube_control {
    display: none;
  }
  .P_MAIN .slider > div:nth-child(2) {
    bottom: 20px;
    right: 20px;
  }
  .P_MAIN .slider > div:nth-child(3) {
    bottom: 20px;
    right: 58px;
  }

  .tube_inner .state_bar {
    left: 0;
    width: calc(100% - 95px);
  }
}
