@charset "utf-8";

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
}

.container div {
  vertical-align: middle;
}
.container h3 {
  margin: 10px 0;
}

/* .container .MuiButton-root {
  display: inline-block;
  height: 35px;
  line-height: 33px;
  padding: 0 20px;
  background-color: #40404e;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
  margin-left: 4px;
} */

.wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.wrap .layout {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: calc(100vh - 223px);
  justify-content: center;
}

.wrap .layout .contents {
  display: table-cell;
  width: 100%;
  /* padding-bottom: 70px; */
  vertical-align: top;
}

.wrap .location:first-child {
  display: none;
}
.layout > .contents:first-child {
  /* padding-top: 60px; */
}
.layout > .sidemenu + .contents {
  width: calc(100% - 260px);
  flex: auto;
  padding-top: 15px;
}

.sidemenu {
  display: flex;
  vertical-align: top;
  padding-right: 0;
  position: relative;
  /* margin-top: -60px; */
}
.sidemenu::before {
  display: none;
  content: '';
  width: 1px;
  height: calc(100% + 54px);
  background-color: #ddd;
  position: absolute;
  top: 0;
  right: 0;
}

/* breadcrumbs */
.location {
  padding-top: 30px;
  margin-bottom: 0;
  text-align: right;
}
.location ul,
.location ul li {
  display: inline-block;
  vertical-align: top;
}
.location ul li {
  position: relative;
  color: #666;
  font-size: 13px;
}
.location ul li + li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 7px;
  margin: 0 8px;
  line-height: 18px;
  background: url(css/images/ico_arrow_r_gray_4x7.png) no-repeat;
  vertical-align: 2px;
}
.location ul li:last-child {
  text-decoration: underline;
}
.location ul li a {
  display: inline-block;
  color: #888;
  font-size: 13px;
}
.location ul li a:hover {
  color: #222;
  text-decoration: underline;
}
.location ul li a.home {
  position: relative;
  padding-left: 19px;
}
.location ul li a.home::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
  width: 14px;
  height: 13px;
  background: url(css/images/ico_home.png) no-repeat;
  opacity: 0.5;
}

@media (max-width: 800px) {
  .container {
    min-height: calc(100vh - 128px);
    width: 100%;
    padding: 0 0px;
  }

  .wrap {
    width: 100%;
    padding: 0;
  }
  .wrap .layout {
    display: block;
  }
  .wrap .layout .nav {
    display: none;
    position: relative;
    width: 100%;
  }
  .wrap .layout .nav .inner {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #e6e6e6;
    overflow-x: auto;
    -ms-overflow-style: none;
    box-shadow: 3px 4px 5px #ccc;
  }
  .wrap .layout .nav .inner::-webkit-scrollbar {
    display: none;
  }
  .wrap .layout .nav h2 {
    position: absolute;
    top: -9999px;
  }
  .wrap .layout .nav ul {
    padding: 0 20px;
  }
  .wrap .layout .nav ul::after {
    content: '';
    display: block;
    clear: both;
  }
  .wrap .layout .nav ul li {
    float: left;
  }
  .wrap .layout .nav ul li + li {
    margin-top: 0;
  }
  .wrap .layout .nav ul li a {
    height: 45px;
    padding: 0 25px;
    font-size: 15px;
    line-height: 45px;
  }
  .wrap .layout .nav ul li a:hover::after {
    content: none;
  }
  .wrap .layout .nav ul li a.cur {
    border-bottom: 2px solid #000;
  }

  .wrap .layout > .contents {
    padding: 30px 20px 40px;
  }

  .sidemenu {
    display: none;
  }
  .location {
    display: none;
  }
}
